/**
 * User: Niko
 * Date: 10.09.2020
 * Time: 12:03
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from '../../context/GlobalStateContext';

export const SignUpButton = ({ event }) => {
  const { setState } = useGlobalStateContext();
  const { registrationPossible, require_registration, waitingList } = event;
  const show = registrationPossible && require_registration;

  if (!show) {
    return null;
  }

  const onSignUpClick = () => {
    setState(prevState => ({
      ...prevState,
      selectedEvent: event,
      showSignUpForm: true,
    }));
  };

  return (
    <button
      className="xdbtn btn btn-sign-up"
      onClick={onSignUpClick}
    >
      {waitingList ? 'Auf Warteliste setzen' : 'Anmelden'}
    </button>

  );
};

SignUpButton.propTypes = {
  event: PropTypes.object.isRequired
};
