/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import { withConfigHoc } from '../context/GlobalConfigContext';
import { useGlobalStateContext } from '../context/GlobalStateContext';
import { useRegistrationFormData } from '../hooks/useRegistrationFormData';
import { useSignUp } from '../hooks/useSignUp';
import { Loader } from '../components/Loader';

const styles = {
  overlay: {
    zIndex: 1200
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    WebkitFontSmoothing: 'antialiased',
    maxWidth: '90%',
    minWidth: '60%',
    maxHeight: '90%',
    overflow: 'hidden'
  }
};

const createMarkup = (html) => {
  return ({ __html: html });
};

const _SignUpFormController = ({ rootElement }) => {
  Modal.setAppElement(`#${rootElement}`);
  const form = useRef();
  const { showSignUpForm, selectedEvent, setState: setGlobalState } = useGlobalStateContext();
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [{ data: html, isLoading: formDataLoading }, getRegistrationFormData] = useRegistrationFormData(selectedEvent?.uuid);
  const [{ success, isLoading, isError }, signUp] = useSignUp();

  useEffect(() => {
    if (selectedEvent && selectedEvent.uuid) {
      getRegistrationFormData(selectedEvent.uuid);
    }
  }, [selectedEvent, getRegistrationFormData]);

  useEffect(() => {
    if (isLoading) {
      setSubmitDisabled(true);
      setMessage('Formular wird übertragen...');
    } else if (success) {
      setSubmitDisabled(true);
      setMessage('Danke für Ihre Anmeldung!');
      setTimeout(() => {
        refreshPage();
        setSubmitDisabled(false);
        setMessage(null);
      }, 5000);
    }
    if (isError) {
      setSubmitDisabled(false);
      setMessage(`Es ist ein Fehler aufgetreten!`);
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  }, [success, isError, isLoading]);

  const onRequestClose = () => {
    setGlobalState(prevState => ({
      ...prevState,
      selectedEvent: null,
      showSignUpForm: false,
    }));
  };

  const refreshPage = ()=>{
    window.location.reload();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    setFormError(null);
    formData.append("bookingUuid", selectedEvent.uuid);
    formData.append("defaultLanguageId", 'de_DE');
    signUp(formData);
  };

  return (
    <>
      {
        showSignUpForm &&
        <Modal
          isOpen={showSignUpForm}
          onRequestClose={onRequestClose}
          style={styles}
          aria={{
            labelledby: 'modal-heading',
            modal: true
          }}
          role="dialog"
        >
          <h1 id="modal-heading">Für Veranstaltung anmelden</h1>

          <div className="compact">
            <Loader loading={formDataLoading}/>
            <div className="register-form">
              <form ref={form} onSubmit={onSubmit} id="sign-up-form" enctype="multipart/form-data">
                <div dangerouslySetInnerHTML={createMarkup(html)}/>
                {
                  selectedEvent && selectedEvent.gtc && (
                    <>
                      <div className="agb">
                        <a
                          href={selectedEvent.gtc}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Allgemeine Geschäftsbedingungen (Deutschland)
                        </a>
                      </div>

                      <div>
                        <input
                          className={'agb__checkbox'}
                          type="checkbox"
                          id="agb__checkbox"
                          required
                        />
                        <label
                          className="agb__label"
                          htmlFor="agb__checkbox"
                        >
                          Ich habe die AGB zur Kenntnis genommen.
                        </label>
                      </div>
                    </>
                  )
                }


                {
                  selectedEvent && selectedEvent.privacy_policy &&
                  <>
                    <div className="agb">
                      <a
                        href={selectedEvent.privacy_policy}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Datenschutzerklärung
                      </a>
                    </div>
                    <div>
                      <input
                        className={'agb__checkbox'}
                        type="checkbox"
                        id="privacy__checkbox"
                        required
                      />
                      <label
                        className="agb__label"
                        htmlFor="privacy__checkbox"
                      >
                        Ich habe die Datenschutzerklärung zur Kenntnis genommen.
                      </label>
                    </div>
                  </>
                }
              </form>
            </div>
            <br/>
            <br/>
          </div>

          <span className="xdlegend">Pflichtfelder</span>

          {
            formError &&
            <div className={'status-field alert alert-danger'}>
              {formError}
            </div>
          }
          <StatusMessage
            isLoading={isLoading}
            isError={isError}
            success={success}
            message={message}
          />

          <div className="xdbtn--bar">
            <button
              className="xdbtn--submit"
              type="button"
              onClick={onRequestClose}
            >
              Abbrechen
            </button>

            <button
              className="xdbtn--submit"
              type="submit"
              form="sign-up-form"
              disabled={isSubmitDisabled}
            >
              {selectedEvent && selectedEvent.waitingList ? 'Auf die Warteliste setzen' : 'Anmelden'}
            </button>
          </div>
        </Modal>
      }
    </>
  );

};

const StatusMessage = ({ isLoading, success, isError, message }) => {
  if (!message) {
    return null;
  }
  let className = 'status-field alert alert-info';
  if (isError) {
    className = 'status-field alert alert-danger';
  } else if (success) {
    className = 'status-field alert alert-success';
  }

  return <div className={className} role={'alert'}>{message}</div>;
};

const SignUpFormController = withConfigHoc(_SignUpFormController);
SignUpFormController.displayName = 'SignUpFormController';
export default SignUpFormController;

SignUpFormController.propTypes = {
  portletId: PropTypes.string,
  selectedEvent: PropTypes.object,
  showSignUpForm: PropTypes.bool,
  onRequestClose: PropTypes.func
};


