/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */


import React from 'react';
import dayjs from 'dayjs';
import { isTrue } from '../../utils/utils';
import { Icon } from '../Icon';


const EventMetaEW = ({ event, config, structureFields }) => {

  const dateEnrolStart = dayjs(event.date_enrol_start);
  const dateEnrolEnd = dayjs(event.date_enrol_end);

  if (Object.keys(event).length === 0) {
    return null;
  }
  return (
    <table>
      <tbody>
      {
        isTrue(config.singleAppointment) && event.singleAppointments &&
        <tr>
          <th valign="top">Einzeltermine:</th>
          <td>
            <ul className="link-list">
              {
                event.singleAppointmentStartTime.map((item, i) => (
                    <li className="link-item">
                      {`${dayjs(item).format('DD.MM.YYYY, HH:mm')} Uhr bis ${dayjs(event.singleAppointmentEndTime[i]).format('HH:mm')} Uhr`}
                    </li>
                  )
                )
              }
            </ul>
          </td>
        </tr>
      }
      {
        isTrue(config.city) && event.city &&
        <tr>
          <th valign="top">PLZ/Ortsname:</th>
          <td>{event.city}</td>
        </tr>
      }
      {
        isTrue(config.location) && event.location &&
        <tr>
          <th valign="top">Adresse/Ortsbeschreibung:</th>
          <td>{event.location}</td>
        </tr>
      }

      {
        event.vocabularies && event.vocabularies.map(v => {
          if (isTrue(config[v.id])) {
            return (
              <tr key={v.id}>
                <th valign="top">{v.label}:</th>
                <td>{v.categories.map(c => {
                  return c.label;
                }).join(', ')}</td>
              </tr>
            );
          }
        })
      }

      {
        structureFields && structureFields.custom && structureFields.custom.map(f => {
          if (isTrue(config[f.key]) && event[f.key]) {
            return (
              <tr key={f.key}>
                <th valign="top">{f.label}</th>
                <td>{event[f.key]}</td>
              </tr>
            );
          }
        })
      }
      </tbody>
    </table>
  );

};

export default EventMetaEW;

const calcDuration = (duration) => {
  const tempTime = dayjs.duration(parseInt(duration));
  const hours = tempTime.asHours();

  if (hours < 1) {
    return `${tempTime.asMinutes()} Minuten`;
  } else if (hours === 1) {
    return 'Eine Stunde';
  } else if (hours % 1 !== 0) {
    return `${tempTime.hours()}:${tempTime.minutes()} Stunden`;
  }

  return `${hours} h`;
};

const targetGroupString = (age) => {
  age = parseInt(age);
  if (age === 0) {
    return 'Ohne Altersbeschränkung';
  } else {
    return `ab ${age} Jahren`;
  }
};

const getFreePlacesString = (freePlaces) => {
  freePlaces = parseInt(freePlaces);
  if (freePlaces < 0) {
    return 'Unbegrenzt';
  } else if (freePlaces === 0) {
    return 'Keine freien Plätze mehr!';
  } else if (freePlaces === 1) {
    return 'Noch 1 Platz frei';
  } else if (freePlaces > 1) {
    return `Noch ${freePlaces} Plätze frei`;
  }
};

const getFreePlacesState = (freePlaces, waitingList) => {
  if (waitingList) {
    return 'yellow';
  }
  freePlaces = parseInt(freePlaces);
  if (freePlaces < 0) {
    return 'green';
  } else if (freePlaces === 0) {
    return 'red';
  } else if (freePlaces === 1) {
    return 'green';
  } else if (freePlaces > 1) {
    return 'green';
  }
};

