/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';

export class FilterWrapperEW extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      collapsed: props.initialCollapsed
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick () {
    if (this.props.collapsible) {
      this.setState({
        collapsed: !this.state.collapsed
      });
    }
  }

  render () {
    const { collapsed } = this.state;
    const { title, children, collapsible, centered } = this.props;

    let headl = [];

    if (collapsible) {
      headl.push(<a key="wrapper-header" href="javascript:void(0)" onClick={this.onClick} role="button" aria-expanded={collapsed ? `false`: `true`} className={collapsed ? `collapsed` : ``} title={collapsed ? 'Filter öffnen' : 'Schließen'}>
        <span className="inner-title">{title}</span>
        <span className={`icon-um ${collapsed ? `icon-um--arrow-down` : `icon-um--close`}`} aria-hidden="true"></span>
      </a>);
    } else {
      headl.push(<span key="wrapper-header" className="inner-title">{title}</span>);
    }

    return (
      <div className={`filter-wrapper ${centered ? 'flex-center' : ''} ${collapsed ? '' : 'open'}`}>
                <span className="filter-wrapper__title">
                    {headl}
                </span>
        <div className="filter-wrapper__content">
          {!collapsed && children}
        </div>
      </div>
    );
  }
}

FilterWrapperEW.propTypes = {
  title: PropTypes.string,
  collapsible: PropTypes.bool,
  initialCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default FilterWrapperEW;
