/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../SearchInput';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { Loader } from '../Loader';
import SearchResultsEW from '../SearchResults/SearchResultsEW';
import { ResultsCount } from '../SearchResults/ResultsCount';

export const EventCalendarEW = ({
  query, searchResults, onFilterClick, onFilterReset,
  onDisplayTypeChange, isLoading,
}) => {
  const { config } = useGlobalConfigContext();
  const { portletSetupTitle_de_DE, introductionText_de_DE } = config;

  return (
    <div className="event-calendar event-calendar--ew">
      <div className="container-max-width">
        <div className="article">
          <div className="main-content">
            <p className="spitzmarke"> Veranstaltungskalender </p>
            <h1 className="title"> {portletSetupTitle_de_DE || 'Veranstaltungskalender'}</h1>
          </div>
        </div>

        {
          introductionText_de_DE &&
          <div className="content-width--ew">
            <section className="introductory-text">
              <div className="teaser-content">
                <p>{introductionText_de_DE}</p>
              </div>
            </section>
          </div>
        }

        <div className="content-width--ew">
          <SearchInput label="Suche im Veranstaltungskalender"/>
          <ResultsCount searchResults={searchResults}/>
        </div>
      </div>


      <div className="event-calendar-content" role="region">
        <Loader loading={isLoading}/>

        <SearchResultsEW
          query={query}
          searchResults={searchResults}
          onDisplayTypeChange={onDisplayTypeChange}
          onFilterClick={onFilterClick}
          onFilterReset={onFilterReset}
        />
      </div>
    </div>
  );
};

EventCalendarEW.displayName = 'EventCalendarEW';
EventCalendarEW.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
};


