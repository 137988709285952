/**
 * Created by Carsten Bühler
 * User: CBuehler on 22.10.2015
 * Copyright: xdot GmbH 2015
 */

export const GOOGLE_API_KEY = 'AIzaSyAe0quKRN9icDJk5auW9tnITJRB4dfIw1I';
export const GOOGLE_MAPS_VERSION = '3.33';

export const DEFAULT_DATE_FORMAT = 'dddd, DD.MM.YYYY';
export const LIST_ITEM_DATE_FORMAT = 'dd., DD.MM.YYYY';
export const SHORT_DATE_FORMAT = 'dd, DD.MM.YYYY';

export const DEFAULT_CALENDAR_IMAGE_COPYRIGHT = 'Copyright: Pixel Embargo, https://de.fotolia.com/id/47950813#';

