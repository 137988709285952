/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Carousel from '../components/Carousel';
import EventListItem from '../components/Listitem/EventListItem';
import SignUpFormController from './SignUpFormController';
import { withConfigHoc } from '../context/GlobalConfigContext';
import { useSearch } from '../hooks/useSearch';
import { Loader } from '../components/Loader';
import CurrentEvents from '../components/CurrentEvents/CurrentEvents';

const _CurrentEventsController = ({ isEventDetail, uuid, categoryIds, namespace, config }) => {
  const [query, setQuery] = useState({
    calendarIds: (config && config.calendarId && config.calendarId.split(',')) || null,
    recurring: true,
    keywords: '',
    dateRangeVal: '1',
    startTime: dayjs().startOf('day').valueOf(),
    endTime: dayjs().add(config.numberOfDays || 60, 'days').endOf('day').valueOf(),
    includeEvents: true,
    includeOfferings: false,
    excludeUuids: uuid ? [uuid] : [],
    categoryIds: [],
    ddmStructureKeys: [],
  });
  const [{ data: searchResults, isLoading, isError, success }, search] = useSearch(query);
  const results = searchResults && searchResults.results;
  const perPage = Number.parseInt(config.perPage || 3);

  useEffect(() => {
    setQuery(prevQuery => ({
      ...prevQuery,
      excludeUuids: uuid ? [uuid] : [],
      categoryIds: categoryIds ? categoryIds : []
    }));
  }, [uuid]);

  useEffect(() => {
    search(query);
  }, [query]);

  return (
    <div className={'current-events-widget teaser-cnt-' + perPage}>
      <CurrentEvents
        query={query}
        searchResults={searchResults}
        isEventDetail={isEventDetail}
        namespace={namespace}
        isLoading={isLoading}
      />
      <SignUpFormController/>
    </div>
  );

};

const CurrentEventsController = withConfigHoc(_CurrentEventsController);
CurrentEventsController.displayName = 'CurrentEventsController';
export default CurrentEventsController;

CurrentEventsController.propTypes = {
  isEventDetail: PropTypes.bool,
  uuid: PropTypes.string,
  categoryIds: PropTypes.array,
  namespace: PropTypes.string
};


