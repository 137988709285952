/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import EventDetails from '../components/EventDetails/EventDetails';
import { withConfigHoc } from '../context/GlobalConfigContext';
import { useEventInstance } from '../hooks/useEventInstance';
import dayjs from 'dayjs';
import { useSearch } from '../hooks/useSearch';

const _EventDetailsController = ({ showCurrentEvents, config, structureFields, portletId }) => {
  const [query, setQuery] = useState({
    calendarIds: (config && config.calendarId && config.calendarId.split(',')) || null,
    recurring: true,
    keywords: '',
    dateRangeVal: '1',
    startTime: dayjs().startOf('day').valueOf(),
    endTime: dayjs().add(config.numberOfDays || 30, 'days').endOf('day').valueOf(),
    includeEvents: true,
    includeOfferings: false,
    categoryIds: [],
    ddmStructureKeys: [],
    excludeUuids: []
  });
  const [{ data: searchResults }, search] = useSearch(query);
  const results = searchResults && searchResults.results;

  const { eventId } = useParams();
  const [{ data: event, isLoading, isError, success }, getEventInstance] = useEventInstance(eventId);

  const findWithAttr = (array, attr, value) => {
    if (array) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
    }
    return -1;
  }

  const nextEvent = () => {
    if (event) {
      let index = findWithAttr(results, 'uuid', eventId);

      if (index > -1) {
        if (results.length > (index + 1)) {
          return results[index + 1].uuid;
        }
      }
    }

    return -1;
  }

  const prevEvent = () => {
    if (event) {
      let index = findWithAttr(results, 'uuid', eventId);

      if (index > -1) {
        if (index > 0) {
          return results[index - 1].uuid;
        }
      }
    }

    return -1;
  }
  useEffect(() => {
    search(query);
  }, [query]);

  useEffect(() => {
    getEventInstance(eventId);
  }, [eventId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [event]);

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }
  }, []);

  return (
    <>
      {
        success &&
        <EventDetails
          portletId={portletId}
          event={event}
          config={config}
          structureFields={structureFields}
          showCurrentEvents={showCurrentEvents}
          showBackLink={true}
          nextEvent={nextEvent()}
          prevEvent={prevEvent()}
        />
      }
    </>
  );
};

const EventDetailsController = withConfigHoc(_EventDetailsController);
EventDetailsController.displayName = 'EventDetailsController';
export default EventDetailsController;

EventDetailsController.propTypes = {
  eventId: PropTypes.string
};


