/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017input-wrapper
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import DatePicker from './DatePicker';
import dayjs from 'dayjs';
import { useSearchContext } from '../context/SearchContext';

const EventCalendarDateRangePicker = () => {
  const { query, setQuery, search } = useSearchContext();
  const { startTime, endTime, dateRangeVal, includeOfferings } = query;

  const onBeginDateChange = (date) => {
    var dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0);
    date = dateObj.getTime();
    const q = {
      ...query,
      startTime: date.valueOf(),
      dateRangeVal: -1
    };
    setQuery(q);
    search(q);
  };

  const onEndDateChange = (date) => {
    var dateObj = new Date(date);
    dateObj.setHours(23, 59, 59, 999);
    date = dateObj.getTime();
    const q = {
      ...query,
      endTime: date.valueOf(),
      dateRangeVal: -1
    };
    setQuery(q);
    search(q);
  };

  const onDateRangeValChange = (e) => {
    const value = e.target.value;
    let q = {};
    if (value === -1) {
      q = {
        ...query,
        dateRangeVal: value,
      };
    } else {
      q = {
        ...query,
        dateRangeVal: value,
        startTime: dayjs().startOf('day').valueOf(),
        endTime: dayjs().add(value, 'days').valueOf()
      };
    }
    setQuery(q);
    search(q);
  };

  return (

    <div className="date-range-picker">
      <div className={`date-range-picker-p date-range-select ${includeOfferings ? 'xddisabled' : ''}`}>
        <label
          className="xdlabel"
          htmlFor="event-time-range"
        >
          Zeitraum
        </label>
        <select
          value={dateRangeVal}
          onChange={onDateRangeValChange}
          disabled={includeOfferings}
          id="event-time-range"
        >
          <option value="0">Heute</option>
          <option value="7">In den nächsten 7 Tagen</option>
          <option value="30">In den nächsten 30 Tagen</option>
          <option value="365">Innerhalb eines Jahres</option>
          <option value="1460">Innerhalb der nächsten 4 Jahre</option>
          <option value="-1">Individueller Zeitraum</option>
        </select>
      </div>

      <div className={`date-range-picker-p ${includeOfferings ? 'xddisabled' : ''}`}>
        <label
          className="xdlabel event-start-date"
          htmlFor="event-start-date"
          aria-label="Startdatum der Suche"
        >
          Von
        </label>
        <DatePicker
          id="event-start-date"
          value={startTime}
          onChange={onBeginDateChange}
          disabled={includeOfferings}/>
      </div>
      <div className={`date-range-picker-p ${includeOfferings ? 'xddisabled' : ''}`}>
        <label
          className="xdlabel event-end-date"
          htmlFor="event-end-date"
          aria-label="Enddatum der Suche"
        >
          Bis
        </label>
        <DatePicker
          id="event-end-date"
          value={endTime}
          onChange={onEndDateChange}
          disabled={includeOfferings}
        />
      </div>
    </div>
  );
};

export default EventCalendarDateRangePicker;
