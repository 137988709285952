import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import FilterWrapper from '../FilterWrapper';
import FilterList from '../FilterList';
import DateRangePicker from '../DateRangePicker';

export const SearchResultsFilterEW = ({
  facets,
  query,
  onFilterClick,
  onFilterReset
}) => {
  const { config } = useGlobalConfigContext();
  const { categoryIds, dateRangeVal } = query;
  const defaultDateRange = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 30;

  return (
    <FilterWrapper title="Filter" collapsible key="filter" initialCollapsed>
      <div className="search-results-filter">
        { (categoryIds.length > 0 || defaultDateRange != dateRangeVal ) &&
        <div className="search-results-filter-reset">
          <a
            href="javascript:void(0)"
            role="button"
            onClick={onFilterReset}
          >
            Alle Filter l&ouml;schen
          </a>
        </div>
        }

        {
          <DateRangePicker/>
        }

        <div className="filter-wrapper__categories" aria-live="polite">
        {
          facets && facets.map(f => {
              if (f.categories && f.categories.length > 0) {
                return (

                    <FilterWrapper title={f.label}>
                      <FilterList
                        query={query}
                        facetKey={f.id}
                        facet={f}
                        onFilterClick={onFilterClick}
                        collapsed={true}
                      />
                    </FilterWrapper>
                );
              }
            }
          )
        }
        </div>
      </div>
    </FilterWrapper>
  );
}

SearchResultsFilterEW.displayName = 'SearchResultsFilterEW';
SearchResultsFilterEW.propTypes = {
  facets: PropTypes.array,
  query: PropTypes.object,
  onFilterClick: PropTypes.func,
  onFilterReset: PropTypes.func
};