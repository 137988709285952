import React from 'react';
import PropTypes from 'prop-types';

import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import ActiveFilterList from './ActiveFilterList';

export const ActiveFilterEW = (
  {
    facets,
    query,
    onFilterClick
  }
) => {
  const { config } = useGlobalConfigContext();
  const { categoryIds } = query;

  return (
    <ul>
      {
        facets && facets.map(f => {
          if (f.categories && f.categories.length > 0) {
            return (
              <ActiveFilterList
                categoryIds={categoryIds}
                categories={f.categories}
                onFilterClick={onFilterClick}
             />
            )
          }
        })
      }
    </ul>
  );
}

ActiveFilterEW.displayName = 'ActiveFilterEW';
ActiveFilterEW.propTypes = {
  facets: PropTypes.array,
  query: PropTypes.object,
  onFilterClick: PropTypes.func
};