/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '../DateRangePicker';
import SearchInput from '../SearchInput';
import FilterWrapper from '../FilterWrapper';
import SearchResults from '../SearchResults/SearchResults';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import { Loader } from '../Loader';

export const EventCalendarDefault = ({
  query, searchResults, onFilterClick,
  onDisplayTypeChange, isLoading,
}) => {
  const { config } = useGlobalConfigContext();
  const { portletSetupTitle_de_DE } = config;


  return (
    <div className={"event-calendar"}>
      <h2 className="xdh1">
        {portletSetupTitle_de_DE || 'Veranstaltungskalender'}
      </h2>


      <div className="event-calendar-head">
        <FilterWrapper title="Veranstaltung">
          <SearchInput/>
          <DateRangePicker/>
        </FilterWrapper>
        <div className="clearfix"/>
      </div>

      <div className="event-calendar-content" role="region">
        <Loader loading={isLoading}/>

        <SearchResults
          query={query}
          searchResults={searchResults}
          onDisplayTypeChange={onDisplayTypeChange}
          onFilterClick={onFilterClick}
        />
      </div>
    </div>
  );
};

EventCalendarDefault.displayName = 'EventCalendarDefault';
EventCalendarDefault.propTypes = {
  query: PropTypes.object,
  searchResults: PropTypes.object,
  onFilterClick: PropTypes.func,
};


