/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { withConfigHoc } from '../context/GlobalConfigContext';
import { useSearch } from '../hooks/useSearch';
import { Loader } from '../components/Loader';
import EventMap from '../components/EventMap/EventMap';
import { useSearchContext } from '../context/SearchContext';

const _EventMapController = ({ config }) => {
  const { query, search, setQuery, isLoading, results: searchResults } = useSearchContext();

  const results = searchResults && searchResults.results;
  const { portletSetupTitle_de_DE } = config;
  const defaultDateRangeVal = config.defaultDateRange ? Number.parseInt(config.defaultDateRange) : 30;
  const startDate = config.defaultStartDate ? Date.parse(config.defaultStartDate) : Date.now();

  const onFilterClick = (categoryId) => {
    let { categoryIds } = query;
    if (categoryIds.indexOf(categoryId) === -1) {
      categoryIds.push(categoryId);
    } else {
      categoryIds.splice(categoryIds.indexOf(categoryId), 1);
    }

    search({ ...query, categoryIds });
  };

  const onFilterReset = () => {
    let { categoryIds } = query;

    categoryIds.splice(0, categoryIds.length);

    setQuery({ ...query, categoryIds, dateRangeVal: defaultDateRangeVal, startTime: dayjs(startDate).startOf('day').valueOf(), endTime: dayjs(startDate).add(defaultDateRangeVal, 'days').endOf('day').valueOf()});
  }

  useEffect(() => {
    search(query);
  }, [query]);

  useEffect(() => {
    if (typeof window.showCopyrightMarker !== 'undefined') {
      window.showCopyrightMarker();
    }
  }, []);

  return (
    <>
      <EventMap
        query={query}
        searchResults={searchResults}
        onFilterClick={onFilterClick}
        onFilterReset={onFilterReset}
        isLoading={isLoading}
      />
    </>
  );
};

const EventMapController = withConfigHoc(_EventMapController);
EventMapController.displayName = 'EventMapController';
export default EventMapController;

EventMapController.propTypes = {
  isEventDetail: PropTypes.bool,
  uuid: PropTypes.string
};


