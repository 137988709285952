export const humanTruncate = (orgString, maxLength) => {
  if (orgString && orgString.length > maxLength && maxLength > 3) {
    const cutIdx = maxLength - 3;
    let new_str = orgString.substr(0, cutIdx);

    // Can we make a more 'natural' appearing cut?
    const idxCutAtWordEnd = new_str.lastIndexOf(' ');
    const idxCutAtHyphen = new_str.lastIndexOf('-');

    const natCutIdx = Math.max(idxCutAtHyphen, idxCutAtWordEnd);
    // Use natural cut if result is at least 2/3th of maxLength
    new_str = orgString.substr(0, natCutIdx > (cutIdx * 2 / 3) ? natCutIdx : cutIdx);

    return new_str + ' ...';
  }
  return orgString;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isTrue = (val) => val === 'true' || val === true;

