/**
 * User: Niko
 * Date: 13.09.2020
 * Time: 12:42
 */

import React from 'react';
import PropTypes from 'prop-types';

export const ResultsCount = ({ searchResults }) => {
  const { total, results, facets } = searchResults;

  return (
    <h2 className="xdh4 results-count-headline">
      {
        Number.isInteger(total) && `${total} Veranstaltungen zu Ihrer Suche`
      }
    </h2>
  );
};

ResultsCount.propTypes = {
  searchResults: PropTypes.object
};
