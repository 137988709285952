/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from './Icon';
import { useSearchContext } from '../context/SearchContext';

const EventCalendarSearchInputDefault = ({ id = 'event-search-input', label = '' }) => {
  const { query, setQuery, search } = useSearchContext();
  const { keywords } = query;

  const onKeywordsChange = (e) => {
    const keywords = e.target.value;
    setQuery({ ...query, keywords });
  };

  const onSearch = () => search(query);

  const onKeyUp = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      search(query);
    }
  };

  return (
    <div className="search-input-wrapper">
      <label
        className="xdlabel"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="search-input">
        <input
          id={id}
          type="text"
          placeholder="Suche nach Titel, Ort..."
          value={keywords}
          onChange={onKeywordsChange}
          onKeyUp={onKeyUp}
          aria-label="Geben Sie hier einen Suchbegriff ein"
        />
        <button
          className="xdbtn btn-search"
          onClick={onSearch}
          aria-label="Suche ausführen"
        >
          <Icon icon="search"/>
          <span className="icontext">Suchen</span>
        </button>
      </div>
    </div>
  );
};

export default EventCalendarSearchInputDefault;

EventCalendarSearchInputDefault.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
