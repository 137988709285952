/**
 * Created by Nikolai Gorte
 * User: n.gorte on 27.09.2017
 * Copyright: xdot GmbH 2017
 */

import React from 'react';

import EventMapController from '../controllerViews/EventMapController';
import { GlobalConfigContextProvider } from '../context/GlobalConfigContext';
import { GlobalStateContextProvider } from '../context/GlobalStateContext';
import { SearchContextProvider } from '../context/SearchContext';

export const EventMapWidget = (config) => {

  return (
    <GlobalConfigContextProvider config={config}>
      <SearchContextProvider>
        <GlobalStateContextProvider>
          <EventMapController/>
        </GlobalStateContextProvider>
      </SearchContextProvider>
    </GlobalConfigContextProvider>
  );
};

EventMapWidget.displayName = 'EventMapWidget';

