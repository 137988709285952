import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalConfigContext } from '../../context/GlobalConfigContext';
import FilterWrapper from '../FilterWrapper';
import FilterList from '../FilterList';

export const SearchResultsFilterDefault = ({
  facets,
  query,
  onFilterClick
}) => {
  const { config } = useGlobalConfigContext();

  return (
    <div className="search-results-filter">
      {
        facets && facets.map(f => {
            if (f.categories && f.categories.length > 0) {
              return (
                <FilterWrapper title={f.label} collapsible key={`filter-${f.id}`}>
                  <FilterList
                    query={query}
                    facetKey={f.id}
                    facet={f}
                    onFilterClick={onFilterClick}
                  />
                </FilterWrapper>
              );
            }
          }
        )}
    </div>
  );

}

SearchResultsFilterDefault.displayName = 'SearchResultsFilterDefault';
SearchResultsFilterDefault.propTypes = {
  facets: PropTypes.array,
  query: PropTypes.object,
  onFilterClick: PropTypes.func,
};
